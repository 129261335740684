import React from "react";
import clsx from "clsx";

import { EliteProfilePreviewProps } from "../types";
import * as Button from "components/Button";
import GlobeSVG from "modules/theme/icons/general/globe.svg";
import PersonSVG from "modules/theme/icons/general/person.svg";
import { ButtonStyle } from "components/Button/constants";
import LazyloadImage from "modules/lazyload/Image";
import RichText from "components/RichText";

import * as styles from "./styles.module.scss";

type ACPEliteProfileEntryProps = {
  entry: EliteProfilePreviewProps;
  name: string;
};

const Entry: React.FC<ACPEliteProfileEntryProps> = ({ entry, name }) => {
  const address = [
    entry.address_line_1,
    entry.address_line_2,
    entry.address_city,
    entry.address_state,
    entry.address_country,
    entry.address_postcode,
  ].filter((el) => {
    return el != null;
  });

  const maturityModels = entry.attributes.filter((i) => i.maturity_models);
  const assessorsExists = entry.assessors && entry.assessors.length > 0;
  const itilAssessors = entry.assessors && entry.assessors.filter((i) => i.assessor.itil);
  const p3m3Assessors = entry.assessors && entry.assessors.filter((i) => i.assessor.p3m3);
  const itilAssessorExists = itilAssessors && itilAssessors.length > 0;
  const p3m3AssessorExists = p3m3Assessors && p3m3Assessors.length > 0;
  const countries = entry.attributes.filter((i) => i.countries);

  return (
    <div className={styles.acpEliteProfileEntry}>
      {(maturityModels.length > 0 || entry.logo.length > 0) && (
        <header>
          {entry.logo.length > 0 &&
            (entry.website ? (
              <Button.CustomLink href={entry.website} ariaLabel={`Visit ${name}`}>
                <LazyloadImage alt={entry.logo[0]?.logo.title} icon src={entry.logo[0]?.logo?.url} tagName="img" />
              </Button.CustomLink>
            ) : (
              <LazyloadImage alt={entry.logo[0]?.logo.title} icon src={entry.logo[0]?.logo?.url} tagName="img" />
            ))}
          {maturityModels.length > 0 && (
            <ul className={styles.maturityModels}>
              {entry.attributes
                .filter((i) => i.maturity_models)
                .map((i: any, key: any) => {
                  const model = i.maturity_models.maturity_model_type;
                  const className = clsx(model === "P3M3" && styles.Maturity, model === "ITIL" && styles.ITIL);
                  return (
                    <li key={key} className={className}>
                      <span>{i.maturity_models.maturity_model_type}</span>
                    </li>
                  );
                })}
            </ul>
          )}
        </header>
      )}
      {countries.length > 0 && countries[0]?.countries.country !== "" ? (
        <div className={styles.availability}>
          <p>
            <span>Available in:</span>
            {entry.attributes
              .filter((i) => i.countries)
              .map((i: any, key: any) => {
                return (
                  <React.Fragment key={key}>
                    {i.countries.country}
                    {key !== entry.attributes.filter((t) => t.countries).length - 1 ? ", " : ""}
                  </React.Fragment>
                );
              })}
          </p>
          <GlobeSVG />
        </div>
      ) : (
        <hr />
      )}
      {entry.description && <RichText content={entry.description} />}
      {(entry.website || assessorsExists || address.length > 0) && (
        <footer>
          {assessorsExists && (
            <div className={styles.assessors}>
              <div>
                <PersonSVG />
                {entry.assessors !== undefined && (
                  <p>
                    &nbsp;|&nbsp;
                    <span>
                      {entry.assessors.length} Assessor{entry.assessors.length === 1 ? "" : "s"}
                    </span>
                  </p>
                )}
              </div>
              <ul>
                {itilAssessorExists && (
                  <li>
                    <span>ITIL</span>:&nbsp;
                    {entry.assessors &&
                      entry.assessors
                        .filter((i) => i.assessor.itil)
                        .map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {item.assessor.name}
                              {entry.assessors && index !== entry.assessors.filter((i) => i.assessor.itil).length - 1
                                ? ", "
                                : ""}
                            </React.Fragment>
                          );
                        })}
                  </li>
                )}
                {p3m3AssessorExists && (
                  <li>
                    <span>P3M3</span>:&nbsp;
                    {entry.assessors &&
                      entry.assessors
                        .filter((i) => i.assessor.p3m3)
                        .map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {item.assessor.name}
                              {entry.assessors && index !== entry.assessors.filter((i) => i.assessor.p3m3).length - 1
                                ? ", "
                                : ""}
                            </React.Fragment>
                          );
                        })}
                  </li>
                )}
              </ul>
            </div>
          )}
          {(address.length > 0 || entry.website) && (
            <div className={styles.contact}>
              <div>
                {address.length > 0 && (
                  <p>
                    <span>{name}</span>&nbsp;
                    {address.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {item}
                          {index !== address.length - 1 ? ", " : ""}
                        </React.Fragment>
                      );
                    })}
                  </p>
                )}
              </div>
              {entry.website && (
                <Button.CustomLink href={entry.website} label={`Visit ${name}`} style={ButtonStyle.BRAND_BLACK_GRAPE} />
              )}
            </div>
          )}
        </footer>
      )}
    </div>
  );
};

export default Entry;
