import React from "react";
import { graphql } from "gatsby";

import { defaultValues, model, schema } from "components/ACPEliteProfile/model";
import { ACPEliteProfileQueryTypes, EliteProfilePreviewProps } from "components/ACPEliteProfile/types";

import * as ACPEliteProfile from "components/ACPEliteProfile";

type ACPPageProps = { data: ACPEliteProfileQueryTypes; location: any };

const ACPPage: React.FC<ACPPageProps> = ({ data, location }) => {
  const acpListData = data?.contentstackAcpListPage?.elite_profile_page;
  const pageData = data?.contentstackOrganizations;
  const parentOrganizationData = data?.contentstackOrganizations?.parent_organization;

  const eliteProfile: EliteProfilePreviewProps = {
    acp_tier: pageData?.acp_tier,
    address_line_1: pageData?.address_line_1,
    address_line_2: pageData?.address_line_2,
    address_state: pageData?.address_state,
    address_postcode: pageData?.address_postcode,
    address_city: pageData?.address_city,
    address_country: pageData?.address_country,
    assessors: parentOrganizationData[0]?.assessors || pageData?.assessors,
    attributes: pageData?.attributes,
    description: parentOrganizationData[0]?.description || pageData?.description,
    logo: parentOrganizationData[0]?.logo || pageData?.logo,
    regions: pageData?.regions,
    website: parentOrganizationData[0]?.website || pageData?.website,
  };

  return (
    <ACPEliteProfile.Template
      defaultMeta={data.contentstackGlobalMeta}
      defaultValues={defaultValues}
      eliteProfile={eliteProfile}
      hero_banner={acpListData?.hero_banner}
      id={pageData?.uid}
      location={location}
      name={pageData?.name}
      meta={pageData?.meta}
      model={model}
      schema={schema}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackOrganizations(id: { eq: $id }) {
      ...metaOrganizations
      uid
      acp_tier
      address_line_1
      address_line_2
      address_state
      address_city
      address_country
      address_postcode
      assessors {
        assessor {
          name
          itil
          p3m3
        }
      }
      attributes {
        maturity_models {
          maturity_model_type
        }
        countries {
          country
        }
      }
      description
      logo {
        logo {
          url
        }
      }
      name
      parent_organization {
        assessors {
          assessor {
            name
            itil
            p3m3
          }
        }
        description
        logo {
          logo {
            url
          }
        }
        website
      }
      slug
      title
      website
    }
    contentstackAcpListPage {
      elite_profile_page {
        hero_banner {
          image {
            url
          }
          image_horizontal_position
        }
      }
    }
  }
`;

export default ACPPage;
