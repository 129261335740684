export type useOrganizationSchemaProps = {
  name: string;
  url: string;
  logo: string;
};

export const useOrganizationSchema = ({ name, url, logo }: useOrganizationSchemaProps) => {
  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    name,
    url,
    logo,
  };
};
