import React from "react";
import { ACPEliteProfileProps } from "../types";

import Layout from "components/Layout";
import Meta from "components/Meta";
import * as Hero from "components/Hero";
import { HeroColour, HeroSize } from "components/Hero/Banner";
import * as ACPEliteProfile from "components/ACPEliteProfile";
import AcpEliteProfileQuestions from "components/ACPEliteProfile/Questions";
import { useOrganizationSchema, useOrganizationSchemaProps } from "modules/meta/schema/OrganizationSchema";

const Template: React.FC<ACPEliteProfileProps> = ({
  defaultMeta,
  defaultValues,
  eliteProfile,
  hero_banner,
  id,
  location,
  model,
  meta,
  name,
  schema,
}) => {
  const organizationSchema: useOrganizationSchemaProps = {
    name,
    url: eliteProfile.website,
    logo: eliteProfile.logo[0]?.logo?.url,
  };

  return (
    <Layout>
      <Meta
        defaultMeta={defaultMeta}
        location={location}
        meta={meta}
        schemaMarkup={useOrganizationSchema({ ...organizationSchema })}
      />
      {hero_banner && (
        <Hero.Banner
          background_colour={HeroColour.BLACK_GRAPE}
          hero_image={hero_banner?.image}
          hero_size={HeroSize.LARGE}
          image_horizontal_position={hero_banner?.image_horizontal_position}
          noText={true}
          reverseDivider={true}
        />
      )}
      <div className="container small">
        <ACPEliteProfile.Entry entry={eliteProfile} name={name} />
        <AcpEliteProfileQuestions
          companyName={name}
          defaultValues={defaultValues}
          formType="ACP"
          id={id}
          model={model}
          schema={schema}
        />
      </div>
    </Layout>
  );
};

export default Template;
